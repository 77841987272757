<template>
  <div
    class="pageContainer app-container studentContainer"
    style="position: relative"
  >
    <el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
      <el-tab-pane label="" name="td">
        <span slot="label">
          <i>教师信息</i>
        </span>
      </el-tab-pane>
    </el-tabs>
    <el-row style="margin-left: 30px; margin-block: 10px">
      <el-input
        v-model="formData.real_name"
        size="small"
        placeholder="请输入用户名"
        style="max-width: 200px; margin-right: 10px; margin-left: 10px"
      />
      <el-button
        size="small"
        icon="el-icon-search"
        @click="onSearch"
        plain
        type="primary"
        >查询</el-button
      >
      <el-button
        size="small"
        icon="el-icon-refresh"
        @click="onReset"
        type="warning"
        >重置</el-button
      >
    </el-row>
    <el-table
      stripe
      :data="dataList"
      style="width: 100%; margin-bottom: 20px; color: #000"
      border
      size="mini"
    >
      <el-table-column
        prop="real_name"
        label="姓名"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="联系电话"
        min-width="100"
      ></el-table-column>
      <el-table-column prop="sex" label="性别" min-width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.sex == '1'">男</span>
          <span v-if="scope.row.sex == '2'">女</span>
          <span v-if="scope.row.sex == '0'">未知</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="email"
        label="邮箱"
        min-width="120"
      ></el-table-column>
      <el-table-column prop="avatar" label="头像" min-width="120">
        <template slot-scope="scope">
          <img
            :src="scope.row.avatar"
            style="width: 40px; height: 40px"
            v-viewer
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current_page"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      count: 0,
      current_page: 1,
      per_page: 13,
      activeName: "td",
      dataList: [],
      showSP: false,
      formData: {},
      spword: "",
      showPrint: false,
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    getlist() {
      this.$http
        .post("api/find_teachers_list", {
          page: this.current_page,
          pagesize: this.per_page,
          ktype: "real_name",
          keyword: this.formData.real_name,
        })
        .then((res) => {
          console.log(res.data);
          this.dataList = res.data.data;
          this.count = res.data.page.count;
          this.current_page = res.data.page.current_page;
          this.per_page = res.data.page.per_page;
        });
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.formData = {};
      this.getlist();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getlist();
    },
    handleCancel() {},
    printIframe() {
      var iframe = document.getElementById("printPage");
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
    },
  },
};
</script>


